import React from "react"
import Layout from "../components/layout"

import styles from "../styles/pages/index.module.css"

import squareholderimg from "../images/squareholder.png"
import mainimg from "../images/icon.png"


export default () => {

	return (
		<Layout activePath='' pageTitle="Goldstar Securities, Inc.">
			<div className={"margin-content"}>
				<div className={styles.banner}>
					<img className={styles.bannerimg} alt="" src={mainimg} />
					<div className={styles.bannergap}></div>
					<div className={styles.bannerinfo+" color-background-gray-f3"}>
						<img className={styles.holderimg} alt="" src={squareholderimg} />
						<div className={styles.holdercontent}>
							<div className={styles.holdercontentaligner}></div>
							<div className={styles.holdercontentdata}>
								Welcome to
								<h1>Goldstar<div>Securities, Inc.</div></h1>
								Member: Philippine Stock Exchange
							</div>
							<div className={styles.holdercontentaligner}></div>
						</div>
					</div>
				</div>
				<div className={styles.aboutus+" color-background-gray-f3"}>
					<h2 className={"font-style-regular color-background-gray-f3 text-centered"}>About Us</h2>
					<div>
						<span className={"font-style-bold color-text-black"}>Goldstar Securities Inc.</span> was incorporated in 1989 and remained inactive until-mid 1994. Encouraged by the upward trend in investors' participation in the equities market, Goldstar Securities, Inc. decided to get organized in June 1994. It commenced commercial operation as a Broker/Dealer and became a member of the Philippine Stock Exchange in <span className={"font-style-bold color-text-black"}>January 1995</span>. After only 3 years of operations, Goldstar Securities, Inc. emerged to be one of the industry's respectable contributors to the stock market's growth.
					</div>
				</div>
			</div>
		</Layout>
	)
}


